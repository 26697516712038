import React from 'react'
import { DatePicker } from "antd";
import { Field } from "./index";
import moment from "moment";
import { FieldBase } from "./components/FieldBase";

export class FieldDateTime extends Field {

  constructor(obj = {}) {
    super(obj);
  }

  getComponent (form, fieldChanged) {
    // Cambiamos el formato para que incluya hora
    const dateTimeFormat = ['DD/MM/YYYY HH:mm'];

    return () => (
      <FieldBase
        condition={this.condition}
        form={form}
        size={this.size}
        label={this.label}
        name={this.name}
        value={this.value}
        help={this.help}
        rules={this.rules}
      >
        <DatePicker
          disabled={this.disabled}
          format={dateTimeFormat}
          showTime={{ format: 'HH:mm' }}  // <--- importante para ver el selector de hora
          style={{ width: '100%' }}
          onChange={(value) => {
            fieldChanged();
            form.setFieldsValue({ [this.name]: value });
          }}
        />
      </FieldBase>
    );
  }

  transformToField = (data) => {
    this.name = data.name;
    this.label = data.label;
    this.type = data.type;
    this.size = data.size ?? this.size;
    this.value = this.transformValueToDate(data.value);
    this.condition = data.condition ?? true;
    this.disabled = data.disabled ?? false;
    this.help = data.help;
    this.rules = data.rules;

    return this;
  }

  transformValueToDate = (str) => {

    const result = moment(str, ['YYYY-MM-DD HH:mm']);
    return result.isValid() ? result : undefined;
  }

  transformValueToField = (data) => {
    let result = moment(data, ["DD/MM/YYYY HH:mm", "YYYY-MM-DD HH:mm", "DD/MM/YYYY", "YYYY-MM-DD"]);
    return result.isValid() ? result : undefined;
  };
}
