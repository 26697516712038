import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
	SIGNOUT,
	GET_USER_ME,
	SIGNIN,
	GET_ALL_PROJECTS
} from '../constants/Auth';
import {
	getUserMeSuccess,
	showAuthMessage, signInSuccess,
	signOutSuccess, getAllProjectsSuccess
} from "../actions/Auth";

import UserService from '../api/user';

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
		try {
			// ToDo Revoke token with api.
			// const signOutUser = yield call(FirebaseService.signOutRequest);
			// if (signOutUser === undefined) {
				localStorage.removeItem(AUTH_TOKEN);
				yield put(signOutSuccess(undefined));
				// yield put(signOutSuccess(signOutUser));
			// } else {
			// 	yield put(showAuthMessage(signOutUser.message));
			// }
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* loginUser() {

	yield takeEvery(SIGNIN, function* ({payload}) {
		try {
			const response = yield call(UserService.login, payload);
			if (response.message) {
				yield put(showAuthMessage(response.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, response.token);
				const user = yield call(UserService.getUserMe);
				if (user.message) {
					yield put(showAuthMessage(user.message));
				} else {
					user.token = response.token;
					user.user.changePass = payload.password == "12345678";

					yield put(signInSuccess(user));
				}
			}
		} catch (error) {
			yield put(showAuthMessage('api.error.login'));
		}
	})
}

export function* getUserMe() {
	yield takeEvery(GET_USER_ME, function* () {
		try {
			const user = yield call(UserService.getUserMe);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				yield put(getUserMeSuccess(user));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export function* getAllProjects() {
	yield takeEvery(GET_ALL_PROJECTS, function* () {
		try {
			const allProjects = yield call(UserService.getAllProjects);
			localStorage.setItem("allProjects", JSON.stringify(allProjects.allProjects));
			// Verificación inmediata
			const storedData = localStorage.getItem("allProjects");
			if (storedData) {
				yield put(getAllProjectsSuccess(allProjects.allProjects));

			} else {
				console.error("El dato no se almacenó en sessionStorage");
			}


		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export default function* rootSaga() {
  yield all([
		fork(signOut),
		fork(getUserMe),
		fork(loginUser),
		fork(getAllProjects)
  ]);
}
