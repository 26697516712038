import React from 'react'
import {DatePicker} from "antd";
import {Field} from "./index";
import moment from "moment";
import {FieldBase} from "./components/FieldBase";

export class FieldRangeDate extends Field {

  constructor(obj={}) {
    super(obj);
  }

  getComponent (form) {
    const { RangePicker } = DatePicker;
    const dateFormat = ['DD/MM/YYYY'];

    return () => (
      <FieldBase condition={this.condition} form={form} size={this.size} label={this.label} name={this.name} value={this.value} help={this.help} rules={this.rules}>

        <RangePicker format={dateFormat} style={{ width: '100%' }}/>
      </FieldBase>);
  }

  transformToField = (data) => {
    this.name = data.name;
    this.label = data.label;
    this.type = data.type;
    this.size = data.size ?? this.size;
    this.condition = data.condition ?? true;
    this.rules = data.rules;

    return this;
  }

  transformValueToField = (data) => {
    let result = data.split(',');
    result[0] = moment(result[0],"DD/MM/YYYY");
    result[1] = moment(result[1],"DD/MM/YYYY");
    return result;
  }

}


