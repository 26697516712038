import Utils from "../../../utils";
import {LabelWithTooltip} from "./LabelWithTooltip";
import React from "react";
import {Col, Form} from "antd";

export const FieldBase = (props) => {
  const visible = Utils.evaluateFormula(props.condition, props.form)
  if(visible == false) {
    props.form.setFieldsValue({[props.name]: ""})
  }
  return (<Col className={!visible ? "display-none" : "visible"} xs={24} sm={24} md={props.size}>
    <Form.Item
      label={<LabelWithTooltip label={props.label} help={props.help}/>}
      name={props.name}
      initialValue={props.value}
      rules={props.rules}
    >
      {props.children}
    </Form.Item>
  </Col>)
}