import React from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

export const LabelWithTooltip = ({ label, help }) => {
  return (
    help ?
      <span>
        <span dangerouslySetInnerHTML={{ __html: label }}></span>&nbsp;
        <Tooltip title={<div className="content" dangerouslySetInnerHTML={{ __html: help }}></div>}>
          <QuestionCircleOutlined />
        </Tooltip>
      </span> :
      <span dangerouslySetInnerHTML={{ __html: label }}></span>
  );
};
