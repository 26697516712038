import React from 'react'
import {Select} from "antd";
import {Field} from "./index";
import {FieldBase} from "./components/FieldBase";

const { Option } = Select;


export class FieldMultiselect extends Field {

  constructor(obj={}) {
    super(obj);
  }


  getComponent (form, fieldChanged) {
    return () => (
      <FieldBase condition={this.condition} form={form} size={this.size} label={this.label} name={this.name} value={this.value} help={this.help} rules={this.rules}>

        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Seleccionar"
          optionFilterProp="children"
          onChange={fieldChanged}
          disabled={this.disabled}
          filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {this.options?.map(option => <Option key={option.key}>{option.label}</Option>)}
        </Select>
      </FieldBase>);
  }

  transformToField = (data) => {
    this.options = data.options;
    this.name = data.name;
    this.label = data.label;
    this.type = data.type;
    this.size = data.size ?? 6;
    this.value = data.value == "" ? undefined : this.transformValueToField(data.value);
    this.condition = data.condition ?? true;
    this.disabled = data.disabled ?? false;
    this.rules = data.rules;

    return this;
  }

  transformValueToField = (data) => {
    //Compruebo si viene de formulario o desde url
    return typeof data === 'string' ? data.split(',') : data
  }
}