import React from 'react'
import {TreeSelect} from "antd";
import {Field} from "./index";
import {FieldBase} from "./components/FieldBase";



export class FieldTreeSelect extends Field {

  constructor(obj={}) {
    super(obj);
  }


  getComponent (form) {
    return () => (
      <FieldBase condition={this.condition} form={form} size={this.size} label={this.label} name={this.name} value={this.value} help={this.help} rules={this.rules}>

          <TreeSelect
            showSearch
            allowClear
            treeCheckable={this.treeCheckable}
            filterTreeNode={(search, item) => {
              return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
            }}
            treeData={this.options} />
      </FieldBase>);
  }

  transformToField = (data) => {
    this.options = data.options.map(this.transformOption);
    this.name = data.name;
    this.label = data.label;
    this.type = data.type;
    this.size = data.size ?? this.size;
    this.disabled = data.disabled ?? false;
    this.condition = data.condition ?? true;
    this.treeCheckable = data.treeCheckable ?? false;
    this.help = data.help;
    this.rules = data.rules;

    return this;
  }

  transformOption = (option) => {
    return {
      ...option,
      value: option.value ?? option.key, // Si no tiene `value`, lo asigna igual a `key`
      children: option.children ? option.children.map(this.transformOption) : undefined // Si tiene hijos, los transforma también
    };
  }
}