import React from 'react'
import {Input} from "antd";
import {Field} from "./index";
import {FieldBase} from "./components/FieldBase";

const { TextArea } = Input;

export class FieldTextArea extends Field {

  constructor(obj={}) {
    super(obj);
  }

  getComponent (form) {
    return () => (
      <FieldBase condition={this.condition} form={form} size={this.size} label={this.label} name={this.name} value={this.value} help={this.help} rules={this.rules}>

          <TextArea
            disabled={this.disabled}
            rows={this.rows} />

      </FieldBase>);

  }

  transformToField = (data) => {
    this.name = data.name;
    this.label = data.label;
    this.type = data.type;
    this.size = data.size ?? this.size;
    this.rows = data.rows ?? 4
    this.disabled = data.disabled ?? false;
    this.condition = data.condition ?? true;
    this.help = data.help;
    this.value = data.value ?? data.defaultValue ?? "";
    this.rules = data.rules;

    return this;
  }
}