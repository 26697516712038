import React from 'react'
import {Input} from "antd";
import {Field} from "./index";

import {FieldBase} from "./components/FieldBase";

export class FieldText extends Field {

  constructor(obj={}) {
    super(obj);
  }



  getComponent (form) {
    return () => (
      <FieldBase condition={this.condition} form={form} size={this.size} label={this.label} name={this.name} value={this.value} help={this.help} rules={this.rules}>
        <Input
          disabled={this.disabled}
          placeholder=""

        />
      </FieldBase>);
  }

  transformToField = (data) => {
    this.name = data.name;
    this.label = data.label;
    this.type = data.type;
    this.size = data.size ?? 6;
    this.value = data.value ?? data.defaultValue ?? "";
    this.disabled = data.disabled ?? false;
    this.condition = data.condition ?? true;
    this.help = data.help;
    this.rules = data.rules;
    return this;
  }
}