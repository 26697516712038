import React, {useEffect, useState} from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig';
import utils from 'utils';
import {getAllProjects, getUserMe} from "../services/actions/Auth";


function RouteInterceptor({ children, isAuthenticated, location, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: `${AUTH_PREFIX_PATH}/${utils.getProjectLocation(location)}/login`,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export const Views = (props) => {
  const { locale, token, location, user, getUserMe, getAllProjects, allProjects } = props;
  const currentAppLocale = AppLocale[locale];
  const project = utils.getProjectLocation(location);
  const [allProjectsLoaded, setAllProjectsLoaded] = useState(false)

  //invoco la primera vez las llamadas del token y la configuración de proyectos
  useEffect(() => {
    const fetchData = async () => {
      if (token && !user.username) {
        await getUserMe(token);
      }
      await getAllProjects();
    };

    fetchData();
  }, [token, user, getUserMe, getAllProjects]);


  useEffect(() => {

    if(allProjects.length > 0) {
      setAllProjectsLoaded(true)
    }

  }, [allProjects]);



  //si no ha cargado proyectos muestra un cargando
  if (!allProjectsLoaded) {
    return <div>Loading...</div>;
  }

  else {
    if (location.pathname.split("/")[1] === 'auth' && location.pathname.split("/")[3] == 'error' && !utils.isValidProject(project))
      localStorage.setItem('project', 'global');
    return utils.isValidProject(project) || location.pathname === '/' ?
      (
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
          <ConfigProvider locale={currentAppLocale.antd}>
            <Switch>
              <Route exact path="/">
                {/*Redirige al proyecto a no ser que sea la primera vez que se entra que manda a global*/}
                <Redirect to={`${APP_PREFIX_PATH}/${localStorage.getItem('project')? localStorage.getItem('project') : 'global'}`} />
              </Route>
              <Route path={AUTH_PREFIX_PATH+'/:project1'}>
                <AuthLayout />
              </Route>
              <RouteInterceptor path={APP_PREFIX_PATH+'/:project3'} isAuthenticated={token}>
                <AppLayout location={location}/>
              </RouteInterceptor>
            </Switch>
          </ConfigProvider>
        </IntlProvider>
      ) :
      <Redirect
        to={{
          pathname: `${AUTH_PREFIX_PATH}/${localStorage.getItem('project') ?? 'global'}/error`,
          state: { from: location }
        }}
      />
  }
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale } =  theme;
  const { token, user, allProjects } = auth;
  return { locale, token, user, allProjects }
};

export default withRouter(connect(mapStateToProps, {getUserMe, getAllProjects})(Views));