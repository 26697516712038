import React from 'react'
import {Checkbox} from "antd";
import {Field} from "./index";
import {FieldBase} from "./components/FieldBase";

export class FieldCheckbox extends Field {

  constructor(obj={}) {
    super(obj);
  }

  getComponent (form, fieldChanged) {
    console.log(this.value)
    return () => (
      <FieldBase condition={this.condition} form={form} size={this.size} label={this.label} name={this.name} value={this.value} help={this.help} rules={this.rules}>

          <Checkbox.Group onChange={fieldChanged}>
            {this.options?.map(option => <div><Checkbox  value={option.key}>{option.label}</Checkbox></div>)}
          </Checkbox.Group>
      </FieldBase>
    );
  }


  transformToField = (data) => {
    this.name = data.name;
    this.label = data.label;
    this.type = data.type;
    this.size = data.size ?? 6;
    this.options = data.options;
    this.value = data.value == "" ? undefined : this.transformValueToField(data.value);
    this.disabled = data.disabled ?? false;
    this.condition = data.condition ?? true;
    this.help = data.help;
    this.rules = data.rules;

    return this;
  }

  transformValueToField = (data) => {
    //Compruebo si viene de formulario o desde url
    return typeof data === 'string' ? data.split(',') : data
  }
}
