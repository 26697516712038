import React from 'react'
import {Field} from "./index";

import {FieldBase} from "./components/FieldBase";
import {Tree} from "antd";

export class FieldTreeCheck extends Field {

  constructor(obj={}) {
    super(obj);
  }



  getComponent (form, fieldChanged) {
    const onCheck = (checkedKeys, info) => {
      this.value=checkedKeys;
      form.setFieldsValue({[this.name]: checkedKeys})
      fieldChanged();
    }
    return () => (
      <FieldBase condition={this.condition} form={form} size={this.size} label={this.label} name={this.name} value={this.value} help={this.help} rules={this.rules}>
        <Tree
          checkable
          onCheck={onCheck}
          selectable={false}
          treeData={this.options}
          checkedKeys={this.value}
        />
      </FieldBase>);
  }

  transformToField = (data) => {
    this.name = data.name;
    this.label = data.label;
    this.type = data.type;
    this.size = data.size ?? 6;
    this.value = data.value ?? data.defaultValue ?? "";
    this.disabled = data.disabled ?? false;
    this.condition = data.condition ?? true;
    this.help = data.help;
    this.options = data.options;
    this.rules = data.rules;

    return this;
  }
}