import React from 'react'
import {DatePicker} from "antd";
import {Field} from "./index";
import moment from "moment";
import {FieldBase} from "./components/FieldBase";

const { MonthPicker} = DatePicker;

export class FieldMonthDate extends Field {

  constructor(obj={}) {
    super(obj);
  }

  getComponent (form) {
    return () => (
      <FieldBase condition={this.condition} form={form} size={this.size} label={this.label} name={this.name} value={this.value} help={this.help} rules={this.rules}>

        <MonthPicker placeholder="Seleccione mes" style={{ width: '100%' }}/>

        {/*<DatePicker format={dateFormat} style={{ width: '100%' }}/>*/}
      </FieldBase>)
      ;
  }

  transformToField = (data) => {
    this.name = data.name;
    this.label = data.label;
    this.type = data.type;
    this.size = data.size ?? this.size;
    this.disabled = data.disabled ?? false;
    this.condition = data.condition ?? true;
    this.help = data.help;
    this.rules = data.rules;

    return this;
  }

  transformValueToField = (data) => moment(data,"DD-MM-YYYY");

}


