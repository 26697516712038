import React from 'react'
import {Button, message, Modal} from "antd";
import utils from "../../utils";
import DashboardService from "../../services/api/dashboard";
import FormService from "../../services/api/form";
import Utils from "../../utils";

const { info } = Modal;


export class CustomButton{

  constructor(obj={}) {
    this.name = obj.name;
    this.type = obj.type ?? 'action';
    this.size = obj.size;
    this.color = obj.color;
    this.url = obj.url;
    this.operation = obj.operation;
  }

  getComponent (form, history) {
    return (
      <>
        <Button className="mr-2" type={this.color} htmlType="submit" onClick={() =>this.onClickButton(form, history)}>
          {this.name}
        </Button>
      </>
    );

  }

  onClickButton (form, history) {
    switch (this.type) {
      case 'clear':
        form.resetFields();
        break;
      case 'download':
        this.getDownload();
        break;
      case 'print':
        FormService.callApi({operation: this.operation, id_form: utils.getGenericIdLocation(), id_patient: utils.getPatientLocation(), form: form.getFieldsValue()})
          .then(res => window.open(res?.data?.url, '_blank'))
        break;
      case 'printText':
        FormService.callApi({operation: this.operation, id_form: utils.getGenericIdLocation(), id_patient: utils.getPatientLocation(), form: form.getFieldsValue()})
          .then(res =>
              info({
              title: "Resultados en texto",
              closable: true,
              width: "80%",
              content: <pre style={{maxHeight: "35rem", overflow: "auto", backgroundColor: "#fafafb", padding: "10px"}}>{res.data.texto}</pre>,
              okText: "Copiar",
              onOk() {
                navigator.clipboard.writeText(res.data.texto)
                  .then(() => {
                    message.success('Texto copiado al portapapeles', 3);
                  })
                  .catch(err => {
                    console.error('Error al copiar el texto: ', err);
                  });
              } ,
              onCancel() {
                console.log('Cancel');
              },
            })
          )


        break;
      case 'operation':
        FormService.callApi({operation: this.operation, id_form: utils.getGenericIdLocation(), id_patient: utils.getPatientLocation(), form: form.getFieldsValue()})
        break;
      case 'save':
        this.validateForm(form)
          .then((values) => {
            FormService.save({
              id_form: utils.getGenericIdLocation(),
              id_patient: utils.getPatientLocation(),
              form: values
            })
              .then(res => {
                message.success("Guardado realizado");
                if (res?.data?.url) {
                  history.push(utils.getRedirectWithPatient(res?.data?.url));
                }
              })
              .catch(error => {
                message.error("Error al guardar el formulario.");
              });
          })
        break;
      case 'saveWithParams':
        this.validateForm(form)
          .then((values) => {
            FormService.saveWithParams({
              id_form: utils.getGenericIdLocation(),
              id_patient: utils.getPatientLocation(),
              form: values,
              params: Utils.getJsonSearch()
            })
              .then(res => {
                message.success("Guardado realizado");
              })
              .catch(error => {
                message.error("Error al guardar el formulario.");
              });
          })
        break;

      //Cuando guarda el formulario, reenvía la petición para que recargue la página con un parámetro aleatorio. También mantiene el contexto del paciente
      case 'saveWithParamsReload':
        this.validateForm(form)
          .then((values) => {
            FormService.saveWithParams({
              id_form: utils.getGenericIdLocation(),
              id_patient: utils.getPatientLocation(),
              id_secondary: utils.getSecondGenericIdLocation(),
              form: values,
              params: Utils.getJsonSearch()
            })
              .then(res => {
                message.success("Guardado realizado");
                const refresh = Math.floor(Math.random() * 1000);
                history.push(`${utils.getInitRootProject()}/dashboard/${utils.getIdDashboard()}${utils.getSecondGenericIdLocation() ? '/' + utils.getSecondGenericIdLocation() : ""}?refresh=${refresh}${utils.getPatientLocation() ? '&idPatient=' + utils.getPatientLocation() : ''}`);
              })
              .catch(error => {
                message.error("Error al guardar el formulario.");
              });
          })
        break;
      case 'no-token-filter':
        history.push(`/auth/${utils.getProjectLocation()}/fixed/dashboard/${utils.getIdDashboard()}${this.getURLWithFilter(form.getFieldsValue())}`);
        break;
      //Redirect to URL
      case 'redirect':
        window.location.replace(this.url)
        break;      //Redirect to URL
      case 'externalRedirect':
        window.open(this.url, '_blank');
        break;
      //Internal redirect
      case 'back':
        history.goBack()//example: dashboard/1
        break;
      case 'link':
        history.push(this.url)
        break;
      case 'printer':
        window.print()
        break;
      case 'printerApi':
        FormService.callApi({operation: this.operation, project: utils.getProjectLocation(), hopital: utils.getHospitalLocation(), id_form: utils.getGenericIdLocation(), id_patient: utils.getPatientLocation(), form: form.getFieldsValue()})
        window.print()
        break;
      default:
        history.push(`${utils.getInitRootProject()}/dashboard/${utils.getIdDashboard()}${this.getURLWithFilter(form.getFieldsValue())}`);
        break;
    }
  }

  validateForm(form) {
    return new Promise((resolve, reject) => {
      form.validateFields()
        .then((values) => {
          resolve(values);
        })
        .catch((errorInfo) => {
          console.error("Errores en la validación:", errorInfo);

          // Encuentra el primer campo con error
          const firstErrorField = errorInfo.errorFields[0]?.name;
          if (firstErrorField) {
            form.scrollToField(firstErrorField, {
              behavior: 'smooth',
              block: 'center',
            });
          }

          // Muestra un mensaje de error genérico
          message.error("Por favor corrige los errores en el formulario.");
          reject(errorInfo);
        });
    });
  }

  getURLWithFilter(data){
    const searchUrl = utils.getJsonSearch();
    let uri = searchUrl.idPatient ? '?idPatient='+searchUrl.idPatient+"&" : '?';
    Object.keys(data).forEach(o => {
      if(data[o] !== undefined && data[o] !== '' && data[o] !== null) {
        if(data[o][0]?.key || data[o].constructor.name == 'Object')
        {
          let test = JSON.stringify(data[o]);
          let buff = new Buffer(test);
          data[o] = buff.toString('base64');
          data[o] = 'base64-'+data[o];
        }
        if(data[o].constructor.name === 'Moment')
          data[o] = data[o].format('DD-MM-YYYY');
        if(data[o][0]?.constructor.name === 'Moment'){
          let start = data[o][0].format('DD-MM-YYYY');
          let end = data[o][1].format('DD-MM-YYYY');
          data[o] = start+','+end
        }
        //Comprueba si tiene saltos de línea y los mete en la url
        uri += `${o}=${encodeURIComponent(data[o])}&`;
      }
    })
    return uri;
  }

  getDownload = () => {
    window.open(this.url);
    // DashboardService.getDownload(data)
    //   .then((res) => {
    //     window.open(res.url);
    //   })
  }

  transformToField = (data) => {
    this.name = data.name;
    this.label = data.label;
    this.type = data.type;
    this.color = data.color;
    this.size = data.size ?? this.size;
    this.url = data.url;
    this.operation = data.operation;
    return this;
  }

  transformRemoteSearch = (data) => {

  }
}